var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { attrs: { "no-body": "" } }, [
        _c(
          "div",
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "6", md: "4", sm: "12", xs: "12" } },
                  [
                    _c(
                      "b-form-group",
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "input-group-merge ml-1 mt-1" },
                          [
                            _c(
                              "b-input-group-prepend",
                              { attrs: { "is-text": "" } },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "SearchIcon" }
                                })
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              attrs: {
                                id: "icons-search",
                                placeholder: "Search"
                              },
                              model: {
                                value: _vm.filters["search"],
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "search", $$v)
                                },
                                expression: "filters['search']"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass: " ",
                    attrs: { cols: "6", md: "8", sm: "12", xs: "12" }
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "shadow-sm  mt-1 mr-1  float-right",
                        attrs: { size: "", variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.openFileUploadModel()
                          }
                        }
                      },
                      [_c("span", [_vm._v("+ Add Document")])]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "p-1" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "b-overlay",
                      { attrs: { show: _vm.tableLoading, rounded: "sm" } },
                      [
                        _c(
                          "b-table",
                          {
                            ref: "table",
                            staticClass: "mobile_table_css",
                            attrs: {
                              "current-page": _vm.currentPage,
                              fields: _vm.fields,
                              filter: _vm.filter,
                              "filter-included-fields": _vm.filterOn,
                              items: _vm.getList,
                              "per-page": _vm.pagination.perPage,
                              "sort-by": _vm.sortBy,
                              "sort-desc": _vm.sortDesc,
                              "sort-direction": _vm.sortDirection,
                              hover: "",
                              responsive: ""
                            },
                            on: {
                              "update:sortBy": function($event) {
                                _vm.sortBy = $event
                              },
                              "update:sort-by": function($event) {
                                _vm.sortBy = $event
                              },
                              "update:sortDesc": function($event) {
                                _vm.sortDesc = $event
                              },
                              "update:sort-desc": function($event) {
                                _vm.sortDesc = $event
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "head(created_at)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName:
                                              "v-b-tooltip.hover.viewport",
                                            value: "Sort",
                                            expression: "'Sort'",
                                            modifiers: {
                                              hover: true,
                                              viewport: true
                                            }
                                          }
                                        ]
                                      },
                                      [_vm._v(_vm._s(data.label))]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "table-row",
                                fn: function(data) {
                                  return undefined
                                }
                              },
                              {
                                key: "cell(name)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-media",
                                          { attrs: { "no-body": "" } },
                                          [
                                            data.item.type === "pdf"
                                              ? _c("b-img", {
                                                  staticClass: "mr-1 ",
                                                  attrs: {
                                                    src: require("@/assets/images/icons/file-icons/pdf.png"),
                                                    alt: "browser img",
                                                    height: "30"
                                                  }
                                                })
                                              : _vm._e(),
                                            data.item.type === "docx"
                                              ? _c("b-img", {
                                                  staticClass: "mr-1 ",
                                                  attrs: {
                                                    src: require("@/assets/images/icons/file-icons/docx.png"),
                                                    alt: "browser img",
                                                    height: "30"
                                                  }
                                                })
                                              : _vm._e(),
                                            data.item.type === "excel"
                                              ? _c("b-img", {
                                                  staticClass: "mr-1 ",
                                                  attrs: {
                                                    src: require("@/assets/images/icons/file-icons/excel.png"),
                                                    alt: "browser img",
                                                    height: "30"
                                                  }
                                                })
                                              : _vm._e(),
                                            data.item.type === "png"
                                              ? _c("b-img", {
                                                  staticClass: "mr-1 ",
                                                  attrs: {
                                                    src: require("@/assets/images/icons/file-icons/png.png"),
                                                    alt: "browser img",
                                                    height: "30"
                                                  }
                                                })
                                              : _vm._e(),
                                            data.item.type === "jpg"
                                              ? _c("b-img", {
                                                  staticClass: "mr-1 ",
                                                  attrs: {
                                                    src: require("@/assets/images/icons/file-icons/jpg.png"),
                                                    alt: "browser img",
                                                    height: "30"
                                                  }
                                                })
                                              : _vm._e(),
                                            data.item.type === "xlsx"
                                              ? _c("b-img", {
                                                  staticClass: "mr-1 ",
                                                  attrs: {
                                                    src: require("@/assets/images/icons/file-icons/xls.png"),
                                                    alt: "browser img",
                                                    height: "30"
                                                  }
                                                })
                                              : _vm._e(),
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "align-self-center my-auto font-weight-bolder"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.name) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(action)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.top",
                                            value: "Download",
                                            expression: "'Download'",
                                            modifiers: {
                                              hover: true,
                                              top: true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "btn-icon bg-white text-primary m-1",
                                        attrs: {
                                          size: "23",
                                          variant: "outline-white"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.downloadFile(data.item)
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "DownloadIcon",
                                            size: "18",
                                            variant: "outline-primary"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.top",
                                            value: "Delete",
                                            expression: "'Delete'",
                                            modifiers: {
                                              hover: true,
                                              top: true
                                            }
                                          },
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(255, 255, 255, 0.15)",
                                            expression:
                                              "'rgba(255, 255, 255, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        staticClass:
                                          "bg-white text-danger btn-icon ml-1",
                                        attrs: {
                                          size: "23",
                                          variant: "outline-danger"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteFile(data.item.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "TrashIcon",
                                            size: "18",
                                            variant: "outline-danger"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_vm._v(" > ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.noDataTable === 0
                  ? _c(
                      "b-col",
                      { staticClass: "text-center", attrs: { cols: "12" } },
                      [_c("span", [_vm._v("No data for preview")])]
                    )
                  : _vm._e(),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center justify-content-sm-start",
                    attrs: { cols: "12", sm: "6" }
                  },
                  [
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v(
                        "Showing " +
                          _vm._s(_vm.pagination.from) +
                          " to " +
                          _vm._s(_vm.pagination.to) +
                          " of " +
                          _vm._s(_vm.pagination.totalRows) +
                          " entries"
                      )
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center justify-content-sm-end",
                    attrs: { cols: "12", sm: "6" }
                  },
                  [
                    _c("b-pagination", {
                      staticClass: "mb-0 mt-1 mt-sm-0 ",
                      attrs: {
                        "per-page": _vm.pagination.perPage,
                        "total-rows": _vm.pagination.totalRows,
                        "first-number": "",
                        "last-number": "",
                        "next-class": "next-item",
                        "prev-class": "prev-item"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "prev-text",
                          fn: function() {
                            return [
                              _c("feather-icon", {
                                attrs: { icon: "ChevronLeftIcon", size: "18" }
                              })
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "next-text",
                          fn: function() {
                            return [
                              _c("feather-icon", {
                                attrs: { icon: "ChevronRightIcon", size: "18" }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-center",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            size: "lg",
            title: "Upload Document"
          },
          model: {
            value: _vm.showFileUploadModal,
            callback: function($$v) {
              _vm.showFileUploadModal = $$v
            },
            expression: "showFileUploadModal"
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.modalLoading, rounded: "sm" } },
            [
              _c(
                "b-row",
                { staticClass: "mx-1" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "m-auto",
                        staticStyle: { width: "100px", height: "100px" }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: {
                              "enable-background": "new 0 0 512 512"
                            },
                            attrs: {
                              id: "Layer_1",
                              version: "1.1",
                              viewBox: "0 0 512 512",
                              x: "0px",
                              "xml:space": "preserve",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              y: "0px"
                            }
                          },
                          [
                            _c("rect", {
                              staticStyle: { fill: "#32BEA6" },
                              attrs: { height: "512", width: "512", y: "0" }
                            }),
                            _c("g", [
                              _c("polygon", {
                                staticStyle: { fill: "#FFFFFF" },
                                attrs: {
                                  points:
                                    "182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808\n\t\t329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 \t"
                                }
                              }),
                              _c("polygon", {
                                staticStyle: { fill: "#FFFFFF" },
                                attrs: {
                                  points:
                                    "395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864\n\t\t379.528,405.864 379.528,301.088 395.528,301.088 \t"
                                }
                              })
                            ]),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g")
                          ]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-overlay",
                        { attrs: { show: _vm.buttonLoading, rounded: "sm" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-2",
                            attrs: {
                              options: _vm.fileTypeOption,
                              reduce: function(fileTypeOption) {
                                return fileTypeOption.id
                              },
                              label: "name",
                              placeholder: "Select Document Type",
                              value: "id"
                            },
                            model: {
                              value: _vm.selected_file_type_id,
                              callback: function($$v) {
                                _vm.selected_file_type_id = $$v
                              },
                              expression: "selected_file_type_id"
                            }
                          }),
                          _c("b-form-file", {
                            ref: "image_ref",
                            attrs: {
                              "drop-placeholder": "Drop file here...",
                              placeholder: "Choose a file or drop it here..."
                            },
                            model: {
                              value: _vm.uploadedFile,
                              callback: function($$v) {
                                _vm.uploadedFile = $$v
                              },
                              expression: "uploadedFile"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "mt-5", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "float-right" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              disabled: _vm.isActive,
                              variant: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.uploadFile()
                              }
                            }
                          },
                          [_vm._v(" Upload ")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "warning" },
                            on: {
                              click: function($event) {
                                ;(_vm.isActive = true),
                                  (_vm.showFileUploadModal = false)
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }